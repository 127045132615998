<template>
  <div id="app">
    <camera-view></camera-view>
  </div>
</template>

<script>
import CameraView from './components/CameraView.vue'

export default {
  name: 'App',
  components: {
      CameraView
  }
}
</script>

<style>
</style>
