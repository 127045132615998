<template>
    <div class='ui container'>
        <!-- <img class="bg-image" src="../../src/assets/couple-3798371_1920.jpg" alt="background-image"> -->
        
        <video v-if="!imageData.image && !imageData.dataUrl" ref="video" class="camera-stream" />
        <img v-else :src="imageData.dataUrl" v-bind:style="{transform: 'rotate(' + imageData.image_orientation + 'deg'}" class="camera-image">
        <input type="file" @change="prepareUpload()" ref="file"
               accept="jpg, jpeg, heic, avif"/>

        <div class="loading" v-if="imageData.image && !imageData.dataUrl">
            <img src="../../src/assets/spinner.gif" alt="upload" class="icon-rotate" />
        </div>

        <div class="tips tips-1" v-if="!imageData.image">

        </div>

        <!--
        <img class="camera-image" src="../../src/assets/dummy_900x1600_ffffff_cccccc.png" />
        -->

        <div class="icon-bar" v-if="!imageData.image">
            <a href="#" class="icon icon-rotate"
               v-if="!imageData.image"
            @click="switchCamera()">
                <img src="../../src/assets/rotate.svg" alt="rotate" />
            </a>

            <a href="#" class="icon clicker"
                @click="captureImage()">
                <img src="../../src/assets/camera.svg" alt="camera" />
            </a>

            <a href="#" class="icon"
                @click="selectImage()">
                <img src="../../src/assets/upload.svg" alt="upload" />
            </a>
        </div>

        <div class="icon-bar" v-else>
            <a href="#" class="icon" @click="cancelImage()">
                <img src="../../src/assets/back.svg" alt="terug" />
            </a>
            <a href="#" class="icon"
               @click="uploadImage()" v-if="uploading == false">
                <img src="../../src/assets/upload.svg" alt="upload" />
            </a>

            <a href="#" class="icon icon-rotate"
               v-if="uploading == true">
                <img src="../../src/assets/spinner.gif" alt="upload" />
            </a>
            <a href="#" class="icon">&nbsp;</a>
        </div>

        <div class="click-overlay" v-if="captured">&nbsp;</div>

        <div class="permission-denied" v-if="permissionDenied">
            <p>
                <strong>Camera toegang toestaan</strong>
                Om deze applicatie te gebruiken moet je de browser toegang geven tot de camera. Mogelijk heb je
                hiervoor een vraag om toestemming gekregen van je toestel.
            </p>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            defaultImage: '../../src/assets/camera.png',
            mediaStream: null,
            imageData: {
                image: '',
                image_orientation: 0,
                dataUrl: null
            },
            cameraFacing: 'environment',
            captured: false,
            uploading: false,
            permissionDenied: false
        }
    },
    methods: {
        switchCamera() {
            if (this.cameraFacing == 'environment') {
                this.cameraFacing = 'user';
            } else {
                this.cameraFacing = 'environment';
            }

            this.startVideo()
        },
        captureImage() {
            this.captured = true
            const mediaStreamTrack = this.mediaStream.getVideoTracks()[0]
            const imageCapture = new window.ImageCapture(mediaStreamTrack)
            let reader = new FileReader();
            return imageCapture.takePhoto().then(blob => {
                this.imageData.image = blob
                reader.readAsDataURL(blob)
                reader.onload = () => {
                    this.imageData.dataUrl = reader.result;
                    this.captured = false
                }
            })
        },
        cancelImage() {
            this.imageData.image = null
            this.imageData.dataUrl = null

            this.startVideo()
        },
        startVideo() {
            if (this.mediaStream != null) {
                this.mediaStream.getTracks().forEach(function(track) {
                    track.stop();
                })
                this.mediaStream = null
            }

            navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: this.cameraFacing,
                    // aspectRatio: 16/9
                }
            })
                .then(mediaStream => {
                    this.permissionDenied = false
                    this.$refs.video.srcObject = mediaStream;
                    this.$refs.video.setAttribute('autoplay', '')
                    this.$refs.video.setAttribute('muted', '')
                    this.$refs.video.setAttribute('playsinline', '')
                    this.$refs.video.play()
                    this.mediaStream = mediaStream
                })
                .catch(err => {
                    this.permissionDenied = true
                    console.error(`${err.name}: ${err.message}`);
                })
        },

        selectImage() {
            this.$refs.file.value = null
            this.$refs.file.click()
        },

        prepareUpload() {
            let file = this.$refs.file.files[0]
            this.uploading = false
            // this.imageData.image = URL.createObjectURL(file)
            this.imageData.image = file
            console.log(this.imageData)

            let reader = new FileReader();
            reader.readAsDataURL(file)
            reader.onload = () => {
                this.imageData.dataUrl = reader.result;
                this.captured = false
            }
        },

        uploadImage() {
            this.uploading = true
            const formData = new FormData();
            formData.append('image', this.imageData.image)
            const headers = { 'Content-Type': 'multipart/form-data' };
            axios.post('https://camera.veldhuizenvoortman.nl/backend/storage.php', formData, { headers }).then(() => {
                this.imageData.image = null
                this.imageData.dataUrl = null
                this.$refs.file.value = null
                this.startVideo()
                this.uploading = false
            });
        }
    },
    mounted() {
        navigator.permissions.query({
            name: 'camera'
        }).then(result => {
            if (result.state == 'granted') {
                // document.documentElement.requestFullscreen()
                screen.orientation.lock('portrait')
                this.startVideo()
            } else if(result.state == 'prompt') {
                this.startVideo()
                this.switchCamera()
                this.switchCamera()
            } else {
                this.permissionDenied = true
            }
        })
    }
}
</script>

<style lang="less">
body, html {
    margin: 0;
    background: #000;
    padding: 0;
    overflow: hidden;
}

.bg-image {
    position: absolute;
    top: -1rem;
    left: -1rem;
    right: -1rem;
    bottom: -1rem;
    filter: blur(8px);
}

* {
    box-sizing: border-box;
}

.icon-bar {
    background: rgba(0,0,0,0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 2rem 0;
    z-index: 9;

    display: flex;
    justify-content: space-around;

    .icon {
        width: 3rem;
        max-height: 3rem;

        img {
            width: 3rem;
            aspect-ratio: 1/1;
        }

        &.icon-rotate {
            transition: all .25s ease-in-out;
            transform: rotate(0);

            &:active {
                transform: rotate(180deg);
            }
        }
    }

    .clicker {
        position: relative;
        margin-top: -2rem;
        width: 7rem;
        height: 7rem;

        &:before {
            content: "";
            display: block;
            background: #fff;
            height: 7rem;
            width: 7rem;
            margin-top: -3rem;
            border-radius: 50%;
            transition: all .25s ease-in-out;
        }

        &:after {
            box-sizing: border-box;
            content: "";
            display: block;
            background: transparent;
            border: 0.25rem solid #fff;
            height: 7rem;
            width: 7rem;
            position: absolute;
            top: -3rem;
            left: -0rem;
            border-radius: 50%;
            transition: all .25s ease-in-out;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-1.5rem, -2.4rem);
            max-width: 3rem;
            max-height: 3rem;
            transition: all .25s ease-in-out;
            opacity: 1;
        }

        &:active {
            &:before {
                height: 6rem;
                width: 6rem;
                margin-top: -2.5rem;
                margin-left: .5rem;
            }

            &:after {
                height: 8rem;
                width: 8rem;
                top: -3.5rem;
                left: -0.5rem;
                border-width: .5rem;
            }

            img {
                opacity: 0;
            }
        }
    }
}

/*video {
    width: 100vw;
    height: 100vh;
    position: relative;
    z-index: 2;
}*/

video,
.camera-image {
    aspect-ratio: 0.5625;
    object-fit: contain;
    height: 100vh;
    width: 100vw;
    margin: auto;
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
}

.click-overlay {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 8;
    background: #fff;
    animation: click-overlay-animation .50s ease-in-out;
}

input[type=file] {
    opacity: 0;
}

.loading {
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-100px, -160px);
    width: 64px;
    height: 64px;
    z-index: 99;
}

@keyframes click-overlay-animation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.permission-denied {
    background: #000;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: center;
    z-index: 9999;

    p {
        font-size: 1.7rem;
        max-width: 80vw;
        margin: 0 auto;

        strong {
            display: block;
            font-size: 3rem;
        }
    }
}
</style>